<!-- Details Panel -->
<div class="details-panel" *ngIf="announcementConfigurations">
    <div class="details-action-bar">
        <div class="details-title">
            <h2 class="ellipsis" title="{{ announcementConfigurations.name }}">
                <button type="button" class="btn btn-round me-1" (click)="cancel()" title="{{ 'BACK' | translate }}">
                    <fa-icon icon="times" size="md" class="d-none d-lg-block"></fa-icon>
                    <fa-icon icon="chevron-left" size="md" class="d-lg-none"></fa-icon>
                </button>
                {{ announcementConfigurations.name }}
            </h2>
        </div>

        <div class="details-buttons">
            <!-- Action Buttons -->
            <div class="float-end">
                <div class="btn-group mb-2" role="group">
                    <button
                        type="button"
                        class="btn btn-outline-primary"
                        (click)="editAnnouncementConfigurations(announcementConfigurations.id)"
                        title="{{ 'EDIT' | translate }}"
                        *ngIf="canEdit(announcementConfigurations)"
                    >
                        <fa-icon icon="pencil" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "EDIT" | translate }}</span>
                    </button>
                    <button
                        type="button"
                        class="btn btn-outline-danger"
                        (click)="deleteAnnouncementConfigurations()"
                        title="{{ 'DELETE' | translate }}"
                        *ngIf="canEdit(announcementConfigurations)"
                    >
                        <fa-icon icon="trash-alt" size="sm"></fa-icon>
                        <span class="d-none d-xxl-inline-block ms-1">{{ "DELETE" | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="details-content-accordions">
        <div class="row">
            <div class="col">
                <!-- Details -->
                <ngb-accordion activeIds="ngb-panel-details">
                    <ngb-panel id="ngb-panel-details">
                        <ng-template ngbPanelTitle>
                            <h3 translate title="{{ 'DETAILS' | translate }}">DETAILS</h3>
                            <fa-icon class="chevron-down" size="lg" icon="chevron-down"></fa-icon>
                            <fa-icon class="chevron-up" size="lg" icon="chevron-up"></fa-icon>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <app-announcement-configurations-details
                                [announcementConfigurations]="announcementConfigurations"
                            ></app-announcement-configurations-details>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>
    </div>
</div>
