import { Injectable, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, ReplaySubject, Subscriber, from } from "rxjs";
import { map, share } from "rxjs/operators";
import * as _ from "lodash";

import { AnnouncementsConfigurations } from "@zixi/models";
import { AuthService } from "../../services/auth.service";
import { ZenApiService } from "src/app/services/zen-rpc-service";
import { InferredAPIRequests } from "@zixi/zen-rpc";

@Injectable({
    providedIn: "root"
})
export class AnnouncementsConfigurationsService {
    announcementsConfigurations: Observable<AnnouncementsConfigurations[]>;
    private announcementsConfigurations$: ReplaySubject<AnnouncementsConfigurations[]>;

    private dataStore: {
        announcementsConfigurations: AnnouncementsConfigurations[];
    };

    private lastAnnouncementsConfigurationsRefresh: number;
    private lastAnnouncementConfigurationsRefresh: number;
    private zenApi = inject(ZenApiService);

    constructor(private authService: AuthService, private translate: TranslateService) {
        this.reset();
        this.authService.isLoggedIn.subscribe(isLoggedIn => {
            if (!isLoggedIn) this.reset();
        });
    }

    private reset() {
        this.dataStore = {
            announcementsConfigurations: []
        };

        this.lastAnnouncementsConfigurationsRefresh = null;

        this.announcementsConfigurations$ = new ReplaySubject<AnnouncementsConfigurations[]>(1);
        this.announcementsConfigurations = this.announcementsConfigurations$.asObservable();
    }

    refreshAnnouncementsConfigurations(force?: boolean): Observable<AnnouncementsConfigurations[]> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAnnouncementsConfigurationsRefresh <= 60000)
            return this.announcementsConfigurations;
        this.lastAnnouncementsConfigurationsRefresh = _.now();

        const announcementsConfigurations$ = from(this.zenApi.client.announcementsConfigurations.list()).pipe(share());

        announcementsConfigurations$.subscribe(
            data => {
                const announcementsConfigurations = data.body.result;

                this.dataStore.announcementsConfigurations.forEach((existing, existingIndex) => {
                    const newIndex = announcementsConfigurations.findIndex(
                        announcementConfigurations => announcementConfigurations.id === existing.id
                    );
                    if (newIndex === -1) this.dataStore.announcementsConfigurations.splice(existingIndex, 1);
                });

                announcementsConfigurations.forEach(refreshedAnnouncementConfigurations =>
                    this.updateStore(refreshedAnnouncementConfigurations, true)
                );

                this.announcementsConfigurations$.next(Object.assign({}, this.dataStore).announcementsConfigurations);
            },
            error =>
                // eslint-disable-next-line no-console
                console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ANNOUNCEMENTS_CONFIGURATIONS"), error)
        );
        return announcementsConfigurations$.pipe(map(r => r.body.result));
    }

    refreshAnnouncementConfigurations(id: number, force?: boolean): Observable<AnnouncementsConfigurations> {
        // Only refresh if force is true or last refresh is not in last minute
        if (!force && _.now() - this.lastAnnouncementConfigurationsRefresh <= 60000) {
            return new Observable((observe: Subscriber<AnnouncementsConfigurations>) => {
                observe.next(this.dataStore.announcementsConfigurations.find(r => r.id === id));
                observe.complete();
            });
        }
        this.lastAnnouncementConfigurationsRefresh = _.now();

        const announcementConfigurations$ = from(
            this.zenApi.client.announcementsConfigurations.get({ params: { id } })
        ).pipe(share());

        announcementConfigurations$.subscribe(
            data => {
                const announcementConfigurations = data.body.result;
                announcementConfigurations.hasFullDetails = true;
                this.updateStore(announcementConfigurations, false);
                this.announcementsConfigurations$.next(Object.assign({}, this.dataStore).announcementsConfigurations);
            },
            // eslint-disable-next-line no-console
            error => console.log(this.translate.instant("API_ERRORS.COULD_NOT_LOAD_ANNOUNCEMENT_CONFIGURATIONS"), error)
        );
        return announcementConfigurations$.pipe(map(r => r.body.result));
    }

    getCachedAnnouncementConfigurations(id: number) {
        if (this.dataStore.announcementsConfigurations && id)
            return this.dataStore.announcementsConfigurations.find(
                announcementConfigurations => announcementConfigurations.id === id
            );
        return undefined;
    }

    private updateStore(newAnnouncementConfigurations: AnnouncementsConfigurations, merge: boolean): void {
        const currentAnnouncementConfigurationsIndex = this.dataStore.announcementsConfigurations.findIndex(
            announcementConfigurations => announcementConfigurations.id === newAnnouncementConfigurations.id
        );
        if (currentAnnouncementConfigurationsIndex === -1) {
            this.dataStore.announcementsConfigurations.push(newAnnouncementConfigurations);
            return;
        } else if (merge) {
            const currentAnnouncementConfigurations =
                this.dataStore.announcementsConfigurations[currentAnnouncementConfigurationsIndex];
            Object.assign(currentAnnouncementConfigurations, newAnnouncementConfigurations);
        } else {
            this.dataStore.announcementsConfigurations[currentAnnouncementConfigurationsIndex] =
                newAnnouncementConfigurations;
        }
    }

    async deleteAnnouncementConfigurations(announcementConfigurations: AnnouncementsConfigurations): Promise<boolean> {
        try {
            const result = await this.zenApi.client.announcementsConfigurations.destroy({
                params: { id: announcementConfigurations.id }
            });

            const deletedId = result.body.result;
            const announcementConfigurationsIndex = this.dataStore.announcementsConfigurations.findIndex(
                r => r.id === deletedId
            );
            if (announcementConfigurationsIndex !== -1)
                this.dataStore.announcementsConfigurations.splice(announcementConfigurationsIndex, 1);

            this.announcementsConfigurations$.next(Object.assign({}, this.dataStore).announcementsConfigurations);
            return true;
        } catch (error) {
            return false;
        }
    }

    async createAnnouncementConfigurations(body: InferredAPIRequests["announcementsConfigurations"]["create"]["body"]) {
        try {
            const result = await this.zenApi.client.announcementsConfigurations.create({ body });

            const newAnnouncementsConfigurations = result.body.result;
            this.updateStore(newAnnouncementsConfigurations, false);
            this.announcementsConfigurations$.next(Object.assign({}, this.dataStore).announcementsConfigurations);
            return newAnnouncementsConfigurations;
        } catch (error) {
            return null;
        }
    }

    async updateAnnouncementConfigurations(
        id: number,
        body: InferredAPIRequests["announcementsConfigurations"]["update"]["body"]
    ) {
        try {
            const result = await this.zenApi.client.announcementsConfigurations.update({ body, params: { id } });

            const updatedAnnouncementConfigurations = result.body.result;

            this.updateStore(updatedAnnouncementConfigurations, true);

            this.announcementsConfigurations$.next(Object.assign({}, this.dataStore).announcementsConfigurations);
            return updatedAnnouncementConfigurations;
        } catch (error) {
            return false;
        }
    }
}
